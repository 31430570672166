import React, { memo } from 'react'

import Box from '@mui/material/Box'

import * as S from './styles'

type AuthTemplateProps = {
  children: React.ReactNode
}

const AuthTemplate = ({ children }: AuthTemplateProps) => {
  return (
    <Box width="100%" height="100%" bgcolor="#fff">
      <S.Content>{children}</S.Content>
    </Box>
  )
}

export default memo(AuthTemplate)
