import { Box } from '@mui/system'
import styled from '@mui/system/styled'

export const Wrapper = styled('div')(({ theme }) => ({
  overflowX: 'visible',
  '@media (max-width: 1050px)': {
    display: 'grid',
    gridTemplateRows: '0fr 1fr',
    gridTemplateColumns: '1fr',
    height: '100vh',
    '& img': {
      objectFit: 'cover'
    }
  },
  background: theme.palette.neutral[800],
  display: 'grid',
  gridTemplateColumns: '1fr 680px',
  placeContent: 'center',
  gridTemplateRows: '100vh',
  height: '100%',
  maxHeight: '100vh',
  overflow: 'hidden',
  width: '100%',
  '@media (min-width: 1800px)': {
    width: '80%',
    margin: '0 auto'
  },
  '@media (min-width: 2000px)': {
    width: '70%',
    margin: '0 auto'
  },
  '@media (min-width: 2150px)': {
    width: '60%',
    margin: '0 auto'
  }
}))

export const Header = styled('div')(() => ({
  padding: 16,
  svg: {
    width: 174,
    height: 56
  }
}))

export const Logo = styled('div')(() => ({
  inset: '5px auto auto 5px',
  position: 'absolute',
  top: '65%',
  left: '50%',
  zIndex: 1
}))

export const Content = styled('div')(() => ({
  width: '100%',
  height: '100%',
  background: '#FFF',
  overflowX: 'auto',
  display: 'flex',
  '@media (max-width: 1050px)': {
    gridRow: 2
  }
}))

type ImageWrapperProps = {
  type: 'login' | 'register'
}

export const ImageWrapper = styled(Box)<ImageWrapperProps>(({ type }) => ({
  position: 'relative',
  borderRadius: '15px',
  overflow: 'hidden',
  height: '100%',
  width: '100%',
  backgroundColor: '#fff',
  filter:
    type == 'login' ? 'drop-shadow(0px 0px 10px rgba(77, 152, 240, 0.35))' : '',
  '-webkit-filter':
    type == 'login' ? 'drop-shadow(0px 0px 10px rgba(77, 152, 240, 0.35))' : '',
  boxShadow: type == 'login' ? '0px 0px 30px rgba(77, 152, 240, 0.35)' : '',
  zIndex: 999
}))
