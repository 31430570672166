import React, { useContext } from 'react'
import TagManager from 'react-gtm-module'

import { AuthContext } from 'context/AuthContext'
import { GetServerSideProps } from 'next'
import { parseCookies } from 'nookies'
import LoginTemplate from 'templates/Login'

import Loader from 'components/Loader'
import NextHead from 'components/NextHead'

import { destroySession } from 'utils/DestroySession'

export type LoginProps = {
  token: string
}

const Login = ({ token }: LoginProps) => {
  const tagManagerArgs = {
    gtmId: 'GTM-P7GRR3JZ'
  }

  if (process.browser) {
    TagManager.initialize(tagManagerArgs)
  }

  const { signInWithToken } = useContext(AuthContext)
  if (token) {
    signInWithToken({ token })
    return <Loader />
  }

  return (
    <React.Fragment>
      <NextHead title="Login" />
      <LoginTemplate />
    </React.Fragment>
  )
}

export default Login

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { ['nextauth.token']: token, ['nextauth.role']: role } =
    parseCookies(context)

  if (token && role && !context?.query?.auth) {
    return {
      redirect: {
        destination: '/visao-geral',
        permanent: false
      }
    }
  } else {
    destroySession()
  }

  return {
    props: {
      generatedAt: new Date().toString(),
      token: context?.query?.auth || ''
    }
  }
}
