import React from 'react'

import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'

type LoaderProps = {
  hasOverlay?: boolean
}
function Loader({ hasOverlay = false }: LoaderProps) {
  const overlayStyle = {
    inset: '0 0 0 0',
    position: 'fixed',
    zIndex: 9999999
  }

  return (
    <Box
      sx={{
        display: 'grid',
        height: '100%',
        placeItems: 'center',
        width: '100%',
        ...(hasOverlay && overlayStyle)
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export default Loader
