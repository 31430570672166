import { useContext, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Button, Icon, Textfield } from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import { AuthContext } from 'context/AuthContext'
import Image from 'next/image'
import Link from 'next/link'
import {
  FormLogin as FormLoginFields,
  FormLogin as FormLoginInputs
} from 'templates/Login'

import InputController from 'components/InputController'
import Logo from 'components/Logo'

import { schemaLogin } from '../schema'
import * as S from './styles'

type FormLoginProps = {
  defaultFormValues?: Partial<FormLoginFields>
}

export const FormLogin = ({ defaultFormValues }: FormLoginProps) => {
  const isMobile = useMediaQuery('(max-width:1050px)')
  const isShort = useMediaQuery('(max-height:700px)')

  const currentImage = {
    url: !isShort ? '/images/image-login.png' : '/images/image-login-small.png',
    scale: '1.0',
    objectFit: 'cover',
    outerMargin: '32px'
  }

  const { handleSubmit, control } = useForm<FormLoginFields>({
    defaultValues: { ...defaultFormValues },
    resolver: yupResolver(schemaLogin)
  })

  const { signIn } = useContext(AuthContext)
  const [errorApi, setErrorApi] = useState('')
  const [passwordInputType, setPasswordInputType] = useState('password')

  const handleInputType = () => {
    setPasswordInputType((s) => (s === 'password' ? 'text' : 'password'))
  }

  const handleSignIn: SubmitHandler<FormLoginInputs> = async (data) => {
    const res = (await signIn(data)) as any

    if (res?.status === 401) {
      setErrorApi(res.message)
    }
  }

  return (
    <Box display="flex" height="100%" width="100%">
      <S.Wrapper>
        <S.ContainerForm>
          <S.LogoWrapper>
            <Logo size="normal" id="logo" />
          </S.LogoWrapper>
          <S.Form onSubmit={handleSubmit(handleSignIn)}>
            <Stack gap={2} mb={6}>
              <S.Heading>Seja bem-vindo!</S.Heading>
              <S.SubHeading>Acesse sua base de clientes</S.SubHeading>
            </Stack>
            <InputController control={control} name="email">
              <Textfield label="E-mail:" />
            </InputController>
            <InputController control={control} name="password">
              <Textfield
                label="Senha:"
                type={passwordInputType}
                customEndAdornment={
                  <Icon
                    onClick={handleInputType}
                    icon={
                      passwordInputType === 'password'
                        ? 'visibility'
                        : 'visibilityOff'
                    }
                    sx={{ cursor: 'pointer' }}
                  />
                }
              />
            </InputController>

            <Box width="100%" mt={-2} mb={2}>
              <Link href="/forgot-password" legacyBehavior>
                <Typography
                  sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                  variant="smBold"
                >
                  Esqueceu sua senha?
                </Typography>
              </Link>
            </Box>

            {!!errorApi && (
              <Typography color="#990017" variant="smBold">
                {errorApi}
              </Typography>
            )}

            <Button fullWidth hasFocus buttonSize="lg" type="submit">
              ENTRAR
            </Button>

            {/*<S.OrLine>ou</S.OrLine>*/}
          </S.Form>
          <S.SignUpLink>
            <S.Paragraph>
              Não tem uma conta?{' '}
              <Link href="/registrar" passHref>
                Cadastre-se
              </Link>
            </S.Paragraph>
          </S.SignUpLink>
        </S.ContainerForm>
        <Box width="100%" boxSizing="border-box">
          <Box
            position="relative"
            padding={currentImage.outerMargin}
            boxSizing="border-box"
            bgcolor="#fff"
            display={isMobile ? 'none' : 'block'}
            height="100%"
          >
            <S.ImageWrapper>
              <Image
                alt="clientbase banner"
                src={currentImage.url}
                objectFit={currentImage.objectFit}
                objectPosition="90% 30%"
                fill
                style={{
                  scale: currentImage.scale
                }}
              />
            </S.ImageWrapper>
          </Box>
        </Box>
      </S.Wrapper>
    </Box>
  )
}
